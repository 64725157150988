import React, { Component } from 'react'
import LinkBtn from '../../components/linkBtn/LinkBtn'
import { Link } from 'gatsby'

class LinkTest extends Component {
  render() {
    return (
      <div
        style={{ minHeight: '100vh', marginTop: '5em' }}
        className={'container'}
      >
        <LinkBtn
          size={2}
        >
          <Link to={'/test'}>Test link</Link>
        </LinkBtn>
        <LinkBtn
          size={2}
        >
          <Link to={'/test'}>Test linklinklinklinklinklinklinklinklinklink</Link>
        </LinkBtn>
      </div>
    )
  }
}

export default LinkTest

